// src/js/crm/dashboard.js

// Verificar que Supabase esté disponible
if (typeof supabase === 'undefined') {
  console.error('Supabase no está disponible');
}

// Crear el cliente Supabase
const supabaseClient = supabase.createClient(
  'https://orrliwzquwfttjvyhnqt.supabase.co',
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im9ycmxpd3pxdXdmdHRqdnlobnF0Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzAwNzUyOTAsImV4cCI6MjA0NTY1MTI5MH0.tyZRlsZC0DhgP3TW_XQmFBgWtJhuJZDx-D5nAGVy3UA'
);

// Verificar que el cliente se creó correctamente
console.log('Supabase client initialized:', supabaseClient);

class DashboardCRM {
  constructor() {
    console.log('Initializing DashboardCRM');
    this.contacts = [];
    this.metrics = {
      total: 0,
      pendientes: 0,
      enProceso: 0,
      completados: 0
    };
    this.currentFilters = {
      status: 'todos',
      type: 'todos',
      search: ''
    };

    // Bind de métodos
    this.handleSearch = this.handleSearch.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleTypeFilterChange = this.handleTypeFilterChange.bind(this);
    this.exportToCSV = this.exportToCSV.bind(this);

    // Elementos del DOM
    this.elements = {
      totalContacts: document.getElementById('totalContacts'),
      pendingContacts: document.getElementById('pendingContacts'),
      inProcessContacts: document.getElementById('inProcessContacts'),
      completedContacts: document.getElementById('completedContacts'),
      searchInput: document.getElementById('searchContacts'),
      statusFilter: document.getElementById('statusFilter'),
      typeFilter: document.getElementById('typeFilter'),
      tableBody: document.querySelector('tbody'),
      loadingIndicator: document.getElementById('loadingIndicator'),
      exportButton: document.getElementById('exportButton')
    };

    // Inicializar
    this.init();
  }

  async init() {
    try {
      console.log('Dashboard init started');
      await this.loadInitialData();
      this.attachEventListeners();
      this.initTooltips();
      console.log('Dashboard initialized successfully');
    } catch (error) {
      console.error('Error in initialization:', error);
      this.handleError(error, 'initialization');
    }
  }

  attachEventListeners() {
    console.log('Attaching event listeners');
    if (this.elements.searchInput) {
      this.elements.searchInput.addEventListener('input', this.handleSearch);
    }
    if (this.elements.statusFilter) {
      this.elements.statusFilter.addEventListener('change', this.handleFilterChange);
    }
    if (this.elements.typeFilter) {
      this.elements.typeFilter.addEventListener('change', this.handleTypeFilterChange);
    }
    if (this.elements.exportButton) {
      this.elements.exportButton.addEventListener('click', this.exportToCSV);
    }
  }

  async loadInitialData() {
    try {
      console.log('Loading initial data');
      
      // Primero verificar que podemos hacer una conexión básica
      const { data: testData, error: testError } = await supabaseClient
        .from('contactos')
        .select('count');
        
      console.log('Connection test:', { testData, testError });

      if (testError) {
        console.error('Connection test failed:', testError);
        throw testError;
      }

      await this.applyFilters();
    } catch (error) {
      this.handleError(error, 'loadInitialData');
    }
  }

  async applyFilters() {
    try {
      let query = supabaseClient
        .from('contactos')
        .select('*');

      // Aplicar filtro de estado
      if (this.currentFilters.status !== 'todos') {
        query = query.eq('estado', this.currentFilters.status);
      }

      // Aplicar filtro de tipo
      if (this.currentFilters.type !== 'todos') {
        query = query.eq('tipo_usuario', this.currentFilters.type);
      }

      // Aplicar búsqueda
      if (this.currentFilters.search) {
        query = query.or(`nombre.ilike.%${this.currentFilters.search}%,email.ilike.%${this.currentFilters.search}%`);
      }

      const { data, error } = await query;

      if (error) throw error;

      console.log('Filtered data:', data);
      this.contacts = data;
      this.calculateMetrics();
      this.renderContacts();
      this.updateMetricsDisplay();
    } catch (error) {
      this.handleError(error, 'applyFilters');
    }
  }

  calculateMetrics() {
    this.metrics = this.contacts.reduce((acc, contact) => {
      acc.total++;
      switch (contact.estado) {
        case 'pendiente':
          acc.pendientes++;
          break;
        case 'en_proceso':
          acc.enProceso++;
          break;
        case 'completado':
          acc.completados++;
          break;
      }
      return acc;
    }, {
      total: 0,
      pendientes: 0,
      enProceso: 0,
      completados: 0
    });
    
    console.log('Metrics calculated:', this.metrics);
  }

  updateMetricsDisplay() {
    const { totalContacts, pendingContacts, inProcessContacts, completedContacts } = this.elements;
    
    if (totalContacts) totalContacts.textContent = this.metrics.total;
    if (pendingContacts) pendingContacts.textContent = this.metrics.pendientes;
    if (inProcessContacts) inProcessContacts.textContent = this.metrics.enProceso;
    if (completedContacts) completedContacts.textContent = this.metrics.completados;
  }

  async renderContacts() {
    if (!this.elements.tableBody) return;
    
    // Mostrar estado de carga
    this.elements.tableBody.innerHTML = `
      <tr>
        <td colspan="5" class="text-center">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Cargando...</span>
          </div>
        </td>
      </tr>
    `;

    // Pequeño delay para mostrar el spinner
    await new Promise(resolve => setTimeout(resolve, 200));

    console.log('Rendering contacts:', this.contacts);
    
    if (this.contacts.length === 0) {
      this.elements.tableBody.innerHTML = `
        <tr>
          <td colspan="5" class="text-center text-muted">
            <i class="fe fe-info me-2"></i>
            No se encontraron contactos con los filtros actuales
          </td>
        </tr>
      `;
      return;
    }

    const html = this.contacts.map(contact => `
      <tr>
        <td class="name">
          <div class="d-flex align-items-center">
            <div class="avatar avatar-xs me-2">
              <span class="avatar-title rounded-circle bg-gray-300">
                ${(contact.nombre || '?')[0].toUpperCase()}
              </span>
            </div>
            <div>
              <h4 class="font-weight-normal mb-0">${contact.nombre || 'Sin nombre'}</h4>
            </div>
          </div>
        </td>
        <td class="email">
          <span class="text-muted">${contact.email}</span>
        </td>
        <td class="type text-center">
          ${this.getUserTypeBadge(contact.tipo_usuario)}
        </td>
        <td class="status">
          ${this.getStatusBadge(contact.estado)}
        </td>
        <td class="text-end">
          ${this.getActionButtons(contact.id)}
        </td>
      </tr>
    `).join('');

    this.elements.tableBody.innerHTML = html;
    this.initTooltips();
  }

  getStatusBadge(status) {
    const badges = {
      pendiente: '<span class="badge text-bg-warning-subtle">Pendiente</span>',
      en_proceso: '<span class="badge text-bg-info-subtle">En Proceso</span>',
      completado: '<span class="badge text-bg-success-subtle">Completado</span>',
      contactado: '<span class="badge text-bg-primary-subtle">Contactado</span>'
    };
    return badges[status] || `<span class="badge text-bg-secondary-subtle">${status}</span>`;
  }

  getUserTypeBadge(type) {
    const config = {
      familiar_preocupado: {
        icon: 'fe fe-users',
        text: 'Familiar',
        color: 'text-primary'
      },
      estudiante_crisis: {
        icon: 'fe fe-git-pull-request',
        text: 'Estudiante',
        color: 'text-danger'
      },
      explorador_ansioso: {
        icon: 'fe fe-zoom-in',
        text: 'Explorador',
        color: 'text-warning'
      },
      buscador_proposito: {
        icon: 'fe fe-star',
        text: 'Profesional',
        color: 'text-success'
      }
    };

    const typeConfig = config[type] || {
      icon: 'fe fe-help-circle',
      text: type,
      color: 'text-secondary'
    };

    return `
      <i class="${typeConfig.icon} ${typeConfig.color}" 
         style="font-size: 1.25rem;"
         data-bs-toggle="tooltip" 
         data-bs-title="${typeConfig.text}"
         data-bs-placement="top"></i>
    `;
  }

  getActionButtons(id) {
    return `
      <div class="btn-group">
        <button class="btn btn-sm btn-white d-flex align-items-center" data-action="edit" data-id="${id}">
          <i class="fe fe-edit-2 me-1"></i>
          Editar
        </button>
        <button class="btn btn-sm btn-white d-flex align-items-center" data-action="view" data-id="${id}">
          <i class="fe fe-eye me-1"></i>
          Ver
        </button>
      </div>
    `;
  }

  handleSearch(e) {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    this.searchTimeout = setTimeout(() => {
      this.currentFilters.search = e.target.value.toLowerCase();
      this.applyFilters();
    }, 300); // 300ms debounce
  }

  async handleFilterChange(e) {
    this.currentFilters.status = e.target.value;
    await this.applyFilters();
  }

  async handleTypeFilterChange(e) {
    this.currentFilters.type = e.target.value;
    await this.applyFilters();
  }

  showNotification(message, type = 'success') {
    const alertHtml = `
      <div class="alert alert-${type} alert-dismissible fade show" role="alert">
        ${message}
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      </div>
    `;
    
    const container = document.querySelector('.container');
    if (container) {
      const alertDiv = document.createElement('div');
      alertDiv.innerHTML = alertHtml;
      container.insertBefore(alertDiv, container.firstChild);
      
      setTimeout(() => {
        const alert = bootstrap.Alert.getOrCreateInstance(alertDiv.firstChild);
        alert.close();
      }, 3000);
    }
  }

  handleError(error, context = '') {
    console.error(`Error in ${context}:`, error);
    this.showNotification(
      `Error: ${error.message || 'Ocurrió un error inesperado'}`, 
      'danger'
    );
  }

  exportToCSV() {
    try {
      if (!this.contacts.length) {
        this.showNotification('No hay datos para exportar', 'warning');
        return;
      }

      const headers = ['Nombre', 'Email', 'Tipo', 'Estado', 'Teléfono'];
      const csvContent = [
        headers.join(','),
        ...this.contacts.map(contact => [
          contact.nombre || '',
          contact.email || '',
          contact.tipo_usuario || '',
          contact.estado || '',
          contact.telefono || ''
        ].join(','))
      ].join('\n');

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'contactos_profesion_feliz.csv';
      link.click();

      this.showNotification('Datos exportados correctamente');
    } catch (error) {
      this.handleError(error, 'exportToCSV');
    }
  }

  initTooltips() {
    const tooltips = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    tooltips.forEach(el => {
      if (typeof bootstrap !== 'undefined') {
        new bootstrap.Tooltip(el);
      }
    });
  }
}

// Auto-initialize if we're on the dashboard page
if (document.getElementById('totalContacts')) {
  console.log('Initializing DashboardCRM');
  window.dashboardCRM = new DashboardCRM();
}
